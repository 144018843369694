import { DeleteStoriesModal, DELETE_STORIES_MODAL } from "./DeleteStories";
import { PublishStoriesModal, PUBLISH_STORIES_MODAL } from "./PublishStories";

export const StoriesModals = () => {
    return (
        <>
            <PublishStoriesModal id={PUBLISH_STORIES_MODAL} />
            <DeleteStoriesModal id={DELETE_STORIES_MODAL} />
        </>
    );
};
