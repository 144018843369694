import reduceFields, { reduceList } from "../utils/reduceList";

const mobileVersion = [
    "currentVersions",
];

export const mobileVersionFields = reduceList(mobileVersion);

export const mobileVersionListFields = reduceFields({
    items: mobileVersion
});