import { joiResolver } from "@hookform/resolvers/joi";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import { FormInput } from "../../../../../ui/atoms/FormInput";
import { WinePlaceholder } from "../../../../../ui/atoms/illustration";
import { ImageUpload } from "../../../../../ui/atoms/ImageUpload";
import { FetchSelect } from "../../../../../ui/molecules/FetchSelect";
import {
    throwErrorToast,
    throwSuccessToast
} from "../../../../../ui/organisms/Toaster";
import { useModal } from "../../../../modalpage/hooks";
import { FileType, LessonBlock } from "../../../../types";
import { CertificatesAPI } from "../../../../utils/api/requests/certificates-requests";
import { uploadFile } from "../../../../utils/api/requests/file-requests";
import { PromosAPI } from "../../../../utils/api/requests/promos-requests";
import { useMutation } from "../../../../utils/hooks/useMutation";
import { getEditBlockSchema } from "../../../../utils/schemas/CertificatesSchema";
import { certificatesStore } from "../../../store/CertificatesStore";

interface ModalProps {
    id: string;
}

export const BLOCK_EDIT_MODAL = "BLOCK_EDIT_MODAL";

interface BlockPayload {
    name: string;
    sortNumber: number;
    imageId?: string | null;
    image?: FileType | File | null;
    productCollection: { label: string; value: string } | null;
}
export const BlockModal: FC<ModalProps> = observer(({ id }) => {
    const {
        register,
        closeModal,
        activeModalId,
        modalData,
        modalCallback,
        isPrev
        // lastValue
    } = useModal<{
        item?: {
            block: LessonBlock;
            id: string;
            defaultNumber: number;
        };
    }>();
    const { t } = useTranslation();
    const { certificate } = useContext(certificatesStore);

    const countryId = certificate?.country._id;

    const form = useForm<BlockPayload>({
        mode: "onChange",
        resolver: joiResolver(getEditBlockSchema(t))
    });
    const { handleSubmit, setValue, formState, reset, watch } = form;
    const name = watch("name");

    const { isDirty, isValid } = formState;

    // useEffect(() => {
    //     if (isPrev) {
    //         reset({
    //             name: lastValue || "",
    //             sortNumber: modalData?.item?.defaultNumber
    //         });
    //     } else if (activeModalId !== id) {
    //         reset({
    //             name: "",
    //             sortNumber: undefined
    //         });
    //     } else {
    //         reset({
    //             name: "",
    //             sortNumber: modalData?.item?.defaultNumber
    //         });
    //     }
    // }, [activeModalId, id, reset, modalData, isPrev, lastValue]);

    useEffect(() => {
        register({
            id
        });
    }, [id, register]);

    useEffect(() => {
        if (!isPrev) {
            if (modalData && modalData.item && modalData?.item?.block) {
                reset({
                    image: modalData?.item?.block?.image,
                    imageId: modalData?.item?.block?.imageId,
                    name: modalData?.item?.block?.name,
                    sortNumber: modalData?.item?.block?.sortNumber + 1,
                    productCollection: modalData?.item?.block?.productCollection
                        ? {
                              label:
                                  modalData?.item?.block?.productCollection
                                      ?.name,
                              value:
                                  modalData?.item?.block?.productCollection?._id
                          }
                        : null
                });
            } else {
                reset({
                    sortNumber: modalData?.item?.defaultNumber
                });
            }
        }
    }, [isPrev, modalData, reset, setValue]);
    const { mutate, isLoading } = useMutation({
        fetchFn: async (values: BlockPayload) => {
            let imageId = values.imageId || undefined;

            if (values?.image instanceof File) {
                const { _id } = await uploadFile(values?.image);
                imageId = _id;
            }

            const formData = {
                name: values.name,
                _id: modalData?.item?.block?._id || "",
                sortNumber: values.sortNumber - 1,
                imageId: modalData?.item?.block?.imageId || imageId,
                certificateLevelId: modalData?.item?.id,
                productCollectionId: values.productCollection?.value || null
            };
            return modalData?.item?.block?._id
                ? CertificatesAPI.updateBlock({ formData, id: formData._id })
                : CertificatesAPI.createBlock({ formData });
        },
        onSuccess: data => {
            closeModal();
            const cb = modalCallback[id];
            cb && cb(true, data);
            if (modalData?.item?.block?._id) {
                throwSuccessToast(t(`changesSaved`));
            } else {
                throwSuccessToast(t(`certificate.blockAdded`));
            }
        },
        onError: () => throwErrorToast(t("error"), t("unknownError"))
    });

    const onSubmit = async (values: BlockPayload) => {
        await mutate(values);
    };

    if (activeModalId !== id) return null;

    return (
        <section className="w-600p bg-white rounded-20p flex flex-col text-dark-main">
            <h4 className="text-18 font-semibold p-6">
                {modalData?.item?.block?._id
                    ? `${t("editing")} ${t(
                          "certificate.blocks.plural_2"
                      ).toLowerCase()}`
                    : t(`certificate.addBlock`)}
            </h4>
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ImageUpload
                        EmptyState={WinePlaceholder}
                        name="image"
                        alt={modalData?.item?.block?.name}
                        className="px-25p mt-25p"
                        aspectRatio={1.675}
                    />
                    <span className="flex flex-row justify-between mb-30p mx-25p">
                        <FormInput
                            className="w-374p"
                            name="name"
                            label={t("label")}
                            description={`${name?.length || 0} / 50`}
                            max={50}
                        />
                        <FormInput
                            className="w-150p"
                            name="sortNumber"
                            label={t("certificate.serialNumber")}
                        />
                    </span>
                    <FetchSelect
                        fetchFn={PromosAPI.getProductCollectionList}
                        placeholder={t("notChosen")}
                        label={t("productCollection.plural_0")}
                        name="productCollection"
                        isEditing={true}
                        isSearchable={true}
                        extraArgs={{
                            countyId: countryId
                        }}
                        isClearable={true}
                        className="mb-60p px-25p"
                    />
                    <div className="w-full bg-gray-bg p-6 flex rounded-b-20p">
                        <Button
                            text={
                                modalData?.item?.block?._id
                                    ? t("save")
                                    : t("create")
                            }
                            isDisabled={!isValid || !isDirty || isLoading}
                        />
                        <Button
                            text={t("cancel_2")}
                            type="tertiary"
                            className="ml-4"
                            htmlType="button"
                            onClick={closeModal}
                        />
                    </div>
                </form>
            </FormProvider>
        </section>
    );
});
