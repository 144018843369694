import { TFunction } from "i18next";
import Joi from "joi";
import { PlatformSelection } from "../../types";

export const getPlatformSelectionSchema = (t: TFunction) =>
    Joi.object<PlatformSelection>({
        products: Joi.array().items(
            Joi.object({
                label: Joi.string().required(),
                value: Joi.string().required()
            })
                .messages({
                    "any.required": t("fieldIsRequired"),
                    "object.base": t("fieldIsRequired")
                })
                .unknown(true)
        )
    }).unknown(true);

    export const getAddPlatformSelectionSchemaa = (t: TFunction) =>
    Joi.object({
        country: Joi.object({
            label: Joi.string().required(),
            value: Joi.string().required()
        }).messages({
            "any.required": t("fieldIsRequired"),
            "object.base": t("fieldIsRequired")
        })
            .required()
            .unknown()
    }).unknown();