import { SUPPORTED_LANGUAGES } from "../utils/i18n";
import reduceFields, { reduceList } from "../utils/reduceList";

const listFields = [
    "status",
    {
        product: [
            { name: SUPPORTED_LANGUAGES },
            { photo: ["url"] },
            { manufacturer: ["name"] }
        ]
    },
    "instruments",
    "createDate"
];

export const promoDetailsFields = reduceList([
    "status",
    {
        product: [
            { name: SUPPORTED_LANGUAGES },
            { photo: ["url"] },
            { manufacturer: ["name", { country: ["name"] }] },
            { region: ["name"] },
            { price: ["value", "currency"] },
            "bonusPoints",
            "isAvailableForSale",
            "saleStatus",
            "stockCount"
        ]
    },
    { manufacturer: ["name"] },
    { instruments: ["type", "isLimited", "limitedCount"] }
]);
export const promosListFields = reduceFields({
    items: listFields
});
const videoDefaultFields = [
    {
        product: [
            { name: SUPPORTED_LANGUAGES },
            { manufacturer: ["name"] },
            { photo: ["url"] }
        ]
    },
    "createDate"
];
export const videoListFields = reduceFields({
    items: videoDefaultFields
});
export const videoListForExpertSelect = reduceFields({
    items: [{ expert: ["name"] }, "createDate"]
});
const videoFields = [
    { product: [{ name: SUPPORTED_LANGUAGES }] },
    { expert: ["name"] },
    { links: SUPPORTED_LANGUAGES },
    { youtubeVideoId: SUPPORTED_LANGUAGES },
    {
        quiz: [
            "replyCount",
            {
                questions: SUPPORTED_LANGUAGES.map(lang => ({
                    [lang]: ["question", "answers"]
                }))
            }
        ]
    }
];

export const videoDetailsFields = reduceList(videoFields);

const taskDefailtFields = [
    {
        product: [
            { name: SUPPORTED_LANGUAGES },
            { photo: ["url"] },
            { manufacturer: ["name"] }
        ]
    },
    { expert: ["name"] },
    "createDate",
    "type",
    "productCollectionName"
];

export const tasksListFields = reduceFields({
    items: taskDefailtFields
});

const reviewFields = [
    "lang",
    { tasteScores: ["body", "tannin", "acidity", "sweetness"] },
    "tasteKinds",
    "text",
    "expertText",
    "aftertasteDescription",
    "expertAftertasteDescription",
    "rating",
    "aftertasteDuration",
    { product: [{ name: SUPPORTED_LANGUAGES }] },
    { user: ["name"] }
];
// я их для чего то другого использовал
export const reviewDetailsFields = reduceList(reviewFields);

const taskFields = [
    "type",
    {
        product: [
            { name: SUPPORTED_LANGUAGES },
            "bonusPoints",
            "vintage",
            { manufacturer: ["name"] },
            { region: ["name"] },
            { photo: ["url"] },
            { price: ["value", "currency"] }
        ]
    },
    "status",
    { expert: ["name"] },
    "videoUrl",
    "lang",
    "reason",
    { review: reviewFields },
    "productCollectionName",
    "description",
    {
        productCollection: [
            "name",
            "description",
            { photo: ["url"] },
            {
                products: [
                    "name",
                    { photo: ["url"] },
                    { price: ["value", "currency"] },
                    "newPrice"
                ]
            },
            { country: ["name"] },
            "isPostedByQvino",
            "createDate",
            { expert: ["name"] }
        ]
    },
    { country: ["name"] }
];
export const taskDetailsFields = reduceList(taskFields);

const reviewDefailtFields = [
    {
        product: [
            { name: SUPPORTED_LANGUAGES },
            { manufacturer: ["name"] },
            { photo: ["url"] }
        ]
    },
    "createDate"
];
export const reviewListFields = reduceFields({
    items: reviewDefailtFields
});
export const reviewListForExpertSelect = reduceFields({
    items: [{ user: ["name"] }, "createDate"]
});

export const articleListForSelect = reduceFields({
    items: [{ manufacturer: ["name", "weight"] }, "createDate"]
});

const articleDefaultFields = [
    { manufacturer: ["name", { country: ["name"] }, { logo: ["url"] }] },
    "description",
    {
        coverId: SUPPORTED_LANGUAGES
    },
    {
        blocks: SUPPORTED_LANGUAGES.map(lang => ({
            [lang]: ["type", "imageId", "body"]
        }))
    },
    "quiz",
    "quizCount",
    "viewCount",
    "createDate",
    {
        quiz: [
            {
                questions: SUPPORTED_LANGUAGES.map(lang => ({
                    [lang]: ["question", "answers"]
                }))
            }
        ]
    }
];

export const articleListFields = reduceFields({
    items: articleDefaultFields
});

export const articleFields = reduceList(articleDefaultFields);

const productCollectionDefaultFields = [
    "name",
    "description",
    { photo: ["url"] },
    {
        products: [
            "name",
            { photo: ["url"] },
            { price: ["value", "currency"] },
            "newPrice"
        ]
    },
    { country: ["name"] },
    "isPostedByQvino",
    "createDate",
    { expert: ["name"] }
];

const productCollectionSelectDefaultFields = [
    "name",
    { country: ["name"] },
    "createDate",
    { expert: ["name"] }
];

export const productCollectionListFields = reduceFields({
    items: productCollectionSelectDefaultFields
});

export const productCollectionFields = reduceList(
    productCollectionDefaultFields
);

const productSelectFields = [{ name: ["default"] }];

export const productSelectListFields = reduceFields({
    items: productSelectFields
});

const labelRecognitionDefaultFields = [{ file: ["url"] }, "createDate"];

export const labelRecognitionListFields = reduceFields({
    items: labelRecognitionDefaultFields
});

const storiesDefaultFields = [
    "type",
    { country: ["name"] },
    { photo: ["url"] },
    { thumbnail: ["url"] },
    "title",
    "body",
    "buttonText",
    { user: ["name"] },
    { manufacturer: ["name"] },
    { product: ["name"] },
    { collection: ["name"] },
    "isPublished",
    "isPostedByQvino"
];

export const storiesFields = reduceList(storiesDefaultFields);

const storiesListDefaultFields = [
    "type",
    { country: ["name"] },
    { photo: ["url"] },
    { thumbnail: ["url"] },
    "title",
    "body",
    "buttonText",
    { user: ["name"] },
    { manufacturer: ["name"] },
    { product: ["name"] },
    { collection: ["name"] },
    "isPublished",
    "isPostedByQvino",
    "createDate",
    "updateDate"
];

export const storiesListFields = reduceFields({
    items: storiesListDefaultFields
});

const platformSelectionsListDefaultFields = [
    { country: ["name"] },
    "productIds",
    "createDate",
    "updateDate"
];

export const platformSelectionListFields = reduceFields({
    items: platformSelectionsListDefaultFields
});

const platformSelectionDefaultFields = [
    {
        products: [
            "name",
            { photo: ["url"] },
            { price: ["value", "currency"] },
            "newPrice"
        ]
    },
    { country: ["name"] },
    "createDate"
];

export const platformSelectionFields = reduceList(
    platformSelectionDefaultFields
);
