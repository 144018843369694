import { SUPPORTED_LANGUAGES } from "../utils/i18n";
import reduceFields from "../utils/reduceList";

const fields = [
    "hash",
    { product: [{ name: SUPPORTED_LANGUAGES }] },
    { user: ["name"] },
    "isClaimed",
    "checkCount",
    "createDate"
];

export const productHashFields = reduceFields({ items: fields });
