import {
    LabelRecognitionModal,
    LABEL_RECOGNITION_MODAL
} from "./labelRecognition";

export const LabelRecognitionModals = () => {
    return (
        <>
            <LabelRecognitionModal id={LABEL_RECOGNITION_MODAL} />
        </>
    );
};
