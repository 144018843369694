import { TFunction } from "i18next";
import Joi from "joi";
import { Currencies, DeliveryCountry} from "../../types";

export const getDeliveryCountrySchema = (
    t: TFunction,
) =>
    Joi.object<DeliveryCountry>({
        cost: Joi.object({
            currency: Joi.string()
                .valid(...Currencies)
                .required()
                .messages({
                    "string.empty": t("fieldIsRequired"),
                    "any.required": t("fieldIsRequired"),
                    "any.valid": t("fieldIsRequired")
                }),
            value: Joi.number()
                .min(1)
                .required()
                .messages({
                    "number.min": t("fieldIsRequired"),
                    "number.empty": t("fieldIsRequired"),
                    "number.base": t("fieldIsRequired"),
                    "any.required": t("fieldIsRequired")
                })
        }).required(),
        country: Joi.object({
            label: Joi.string().required(),
            value: Joi.string().required()
        })
            .required()
            .messages({
                "any.required": t("fieldIsRequired"),
                "object.base": t("fieldIsRequired")
            })
            .unknown(true)
        
    }).unknown(true);
