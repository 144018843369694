import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import {
    throwErrorToast,
    throwSuccessToast
} from "../../../../../ui/organisms/Toaster";
import { useModal } from "../../../../modalpage/hooks";
import { PromosAPI } from "../../../../utils/api/requests/promos-requests";

interface DeleteStoriesModalProps {
    id: string;
}

export const DELETE_STORIES_MODAL = "DELETE_STORIES_MODAL";

export const DeleteStoriesModal: FC<DeleteStoriesModalProps> = observer(
    ({ id }) => {
        const {
            register,
            activeModalId,
            closeModal,
            modalData,
            modalCallback
        } = useModal<{
            id: string;
        }>();

        const { t } = useTranslation();
        const [isLoading, setLoading] = useState<boolean>(false);

        useEffect(() => {
            register({
                id
            });
        }, [id, register]);

        const onHandle = async () => {
            setLoading(true);
            try {
                const data = await PromosAPI.deleteStories({
                    id: modalData?.id!
                });
                if (data) {
                    closeModal();
                    throwSuccessToast(t("stories.successDeleted"));
                    const cb = modalCallback[DELETE_STORIES_MODAL];
                    cb && cb(data);
                }
            } catch (error) {
                throwErrorToast(t("error"), t("unknownError"));
            } finally {
                setLoading(false);
            }
        };

        if (activeModalId !== id) return null;

        return (
            <section className="w-600p bg-white rounded-20p flex flex-col text-dark-main">
                <h4 className="text-18 font-semibold p-6">
                    {t(`stories.sureToDeleteStories`)}
                </h4>
                <span className="text-14 leading-5 mb-16 px-6">
                    {t(`stories.storiesWillBeDelete`)}
                </span>

                <div className="w-full bg-gray-bg p-6 flex rounded-b-20p">
                    <Button
                        onClick={closeModal}
                        isDisabled={isLoading}
                        text={t("cancel_2")}
                    />
                    <Button
                        text={t(`delete`) as string}
                        type="tertiary"
                        className="ml-2"
                        onClick={onHandle}
                    />
                </div>
            </section>
        );
    }
);
