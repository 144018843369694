import React, { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useController, useFormContext } from "react-hook-form";
import cn from "classnames";
import { UploaderProps } from "../../../../utils/hooks/useImgHandler";

import { Button } from "../../../../../ui/atoms/Button";
import { CloseSmIcon } from "../../../../../ui/atoms/Icon";
import { throwErrorToast } from "../../../../../ui/organisms/Toaster";

interface ImageUploadProps {
    EmptyState: React.ElementType;
    isDisabled?: boolean;
    name: string;
    alt: string | undefined;
    className?: string;
    aspectRatio?: UploaderProps["aspectRatio"];
}

const maxFileSize = 1024 * 1024 * 3; // 3 Mb

export const ImageUpload: FC<ImageUploadProps> = ({
    isDisabled,
    EmptyState,
    name,
    alt,
    className
}) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const context = useFormContext();
    if (!context) {
        throw new Error("Provide FormContext before ImageUpload");
    }
    const { control } = context;
    const {
        field: { value, onChange },
        fieldState: { error: fieldError }
    } = useController({
        name,
        control
    });
    const [error, setError] = useState(false);

    const onLoad = () => {
        setError(false);
    };

    const onError = () => {
        setError(true);
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        if (file.size > maxFileSize) {
            throwErrorToast(t("error"), t("fileSizeIsToBig"));
            // need clear target
            e.target.value = e.target.defaultValue;
        } else {
            onChange(file);
        }
    };

    const onRemove = () => {
        if (inputRef?.current) {
            inputRef.current.value = "";
        }
        onChange(undefined);
    };

    const Img = (
        <>
            {value && !error ? (
                <img
                    className={"w-144p h-144p rounded-20p object-cover"}
                    src={
                        value instanceof File
                            ? URL.createObjectURL(value)
                            : value.url
                    }
                    alt={alt}
                    onLoad={onLoad}
                    onError={onError}
                />
            ) : (
                <EmptyState
                    className={`w-144p h-144p rounded-20p border-gray-bg  ${
                        fieldError ? "border-danger border-1p" : "border-2"
                    }`}
                />
            )}
        </>
    );

    const wrapperClasses = cn("flex px-6 flex-shrink-0", className);

    return (
        <div className={wrapperClasses}>
            {Img}
            {!isDisabled && (
                <div className="ml-40p flex flex-col text-14 text-gray-text justify">
                    <div>{t("availableFormats")}</div>
                    <div>{t("maxFileSize", { size: 3 })}</div>
                    <label
                        className={`mt-auto ${
                            isDisabled ? "" : "cursor-pointer"
                        }`}
                    >
                        {value ? (
                            <span className="flex flex-row items-center mt-20p h-40p text-dark-main">
                                {value instanceof File ? (
                                    <span>{value?.name}</span>
                                ) : (
                                    <span className="flex flex-row items-center">
                                        {value?.name}
                                    </span>
                                )}
                                <div>
                                    <button
                                        className="w-22p h-22p rounded-full bg-purple-main flex items-center justify-center ml-18p focus:outline-none"
                                        type="button"
                                        onClick={onRemove}
                                    >
                                        <CloseSmIcon />
                                    </button>
                                </div>
                            </span>
                        ) : (
                            <label
                                className={`mt-auto cursor-pointer`}
                                htmlFor={name}
                            >
                                <Button
                                    isDisabled={isDisabled}
                                    htmlType="button"
                                    className="pointer-events-none"
                                    text={t("ar.addLabel")}
                                />
                            </label>
                        )}
                    </label>
                    <input
                        onChange={e => onInputChange(e)}
                        name={name}
                        id={name}
                        type="file"
                        hidden={true}
                        accept="image/png, image/jpeg, image/jpg"
                        ref={inputRef}
                    />
                </div>
            )}
        </div>
    );
};
