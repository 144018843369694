import {
    UpdateCountryOfDeliveryModal,
    UPDATE_COUNTRY_OF_DELIVERY
} from "./UpdateCountryOfDelivery";
import {
    RemoveCountryOfDeliveryModal,
    REMOVE_COUNTRY_OF_DELIVERY
} from "./RemoveCountryOfDelivery/Index";

export const DeliveryCountryModals = () => {
    return (
        <>
            <UpdateCountryOfDeliveryModal id={UPDATE_COUNTRY_OF_DELIVERY} />
            <RemoveCountryOfDeliveryModal id={REMOVE_COUNTRY_OF_DELIVERY} />
        </>
    );
};
