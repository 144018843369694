import { TFunction } from "i18next";
import Joi from "joi";

export const getLabelRecognitionSchema = (t: TFunction) =>
    Joi.object<{product: {value:string, label: string}}>({
        product: Joi.object({
            label: Joi.string().required(),
            value: Joi.string().required()
        })
            .required()
            .messages({
                "any.required": t("fieldIsRequired"),
                "object.base": t("fieldIsRequired")
            })
            .unknown(true),
    }).unknown(true);
