import { LangMap, ListResponse } from "../../types";
import { getLangMapString } from "../../utils/getLangMapString";

export const transformObjectToOption = (words: string[]) =>
    words.map(item => ({
        label: item,
        value: item
    }));

export const transformResponseToOption = (
    data: ListResponse<{ _id: string; name: string | LangMap; iso: string }>
) => {
    return data.items.map(item => ({
        label:
            typeof item.name === "string"
                ? item.name
                : getLangMapString(item?.name, "default"),
        value: item._id,
        iso: item.iso
    }));
};
