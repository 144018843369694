import { SUPPORTED_LANGUAGES } from "../utils/i18n";
import reduceFields, { reduceList } from "../utils/reduceList";

const fields = [
    { name: SUPPORTED_LANGUAGES },
    { description: SUPPORTED_LANGUAGES },
    { price: ["currency", "value"] },
    "saleStatus",
    { photo: ["url"] },
    "photoId",
    "stockCount",
    "createDate"
];

export const giftFields = reduceList(fields);

export const giftListFields = reduceFields({
    items: fields
});
