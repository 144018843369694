import {
    AddPlatformSelectionModal,
    ADD_PLATFORM_SELECTION_MODAL
} from "./AddPlatformSelectionModal";
import {
    PlatformSelectionDeleteModal,
    PLATFORM_SELECTION_DELETE_MODAL
} from "./DeleteModal";

export const PlatformSelectionModals = () => {
    return (
        <>
            <PlatformSelectionDeleteModal
                id={PLATFORM_SELECTION_DELETE_MODAL}
            />
            <AddPlatformSelectionModal id={ADD_PLATFORM_SELECTION_MODAL} />
        </>
    );
};
