import { SUPPORTED_LANGUAGES } from "../utils/i18n";
import reduceFields, { reduceList } from "../utils/reduceList";

const event = [
    { title: SUPPORTED_LANGUAGES },
    { description: SUPPORTED_LANGUAGES },
    { price: ["value", "currency"] },
    { address: SUPPORTED_LANGUAGES },
    { specialNotes: SUPPORTED_LANGUAGES },
    "manufacturerId",
    "startDate",
    "countryId",
    { country: ["name"] },
    {coordinates: ["latitude", "longitude"]},
    {logo: ["url"]},
];

export const eventFields = reduceList(event);

export const eventListFields = reduceFields({
    items: event
});