import { DeleteTask, DELETE_TASK_MODAL } from "./DeleteTask";
import {
    ProductCollectionModal,
    PRODUCT_COLLECTION_TASK_MODAL
} from "./ProductCollectionTask";
import { REJECT_TASK_MODAL, RejectTaskModal } from "./RejectTask";
import { SetTaskModal, SET_TASK_MODAL } from "./SetTask";

export const TasksModals = () => {
    return (
        <>
            <RejectTaskModal id={REJECT_TASK_MODAL} />
            <DeleteTask id={DELETE_TASK_MODAL} />
            <SetTaskModal id={SET_TASK_MODAL} />
            <ProductCollectionModal id={PRODUCT_COLLECTION_TASK_MODAL} />
        </>
    );
};
