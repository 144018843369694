import { FC } from "react";

interface InfoButtonProps {
    setOpened: () => void;
    symbol?: string;
}

export const InfoButton: FC<InfoButtonProps> = ({
    setOpened,
    symbol = "?"
}) => {
    return (
        <div
            className={
                "relative cursor-pointer w-26p h-26p rounded-full bg-gray-light hover:bg-purple-main text-dark-main hover:text-white"
            }
            onClick={setOpened}
        >
            <div className="flex items-center justify-center select-none">
                {symbol}
            </div>
        </div>
    );
};
