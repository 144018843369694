import { LangMap, Languages } from "../types";

export const getLangMapString = (
    langMap: LangMap | undefined,
    language: Languages,
    emptyValue = "—"
): string => {
    if (!langMap) {
        return emptyValue;
    }

    if (langMap.hasOwnProperty(language) && langMap[language]) {
        return langMap[language]!;
    }

    return langMap["en"] ?? emptyValue;
};
