import reduceFields from "../utils/reduceList"

const deliveryCountryDefaultFields = [
    { country: ["name"] },
    { cost: ["value", "currency"] },
]

export const deliveryCountryListFields = reduceFields({
    items: deliveryCountryDefaultFields
})


export const deliveryCountryFields = reduceFields({
    deliveryCountryDefaultFields
})