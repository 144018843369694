import { get, put, remove } from "../axiosConfig";
import { DeliveryCountry, ListResponse } from "../../../types";
import axios, { Canceler } from "axios";
import { deliveryCountryFields, deliveryCountryListFields } from "../../../fields/delivertyCountry";

const CancelToken = axios.CancelToken;
let cancel: Canceler | undefined;

export const DeliveryCountryAPI = {
    async getList({
        skip,
        search,
        limit,
        sort,
        role,
        isQvinoCost = false
    }: {
        skip?: number;
        limit?: number;
        search?: string;
        sort?: string;
        role: "admin" | "manufacturer";
        isQvinoCost?: boolean
    }): Promise<ListResponse<DeliveryCountry>> {
        if (cancel) {
            cancel();
        }
        const params = {
            q: search,
            skip,
            limit,
            sort,
            _fields: deliveryCountryListFields,
            "search[isQvinoCost]": isQvinoCost || null
        };
        const { data } = await get(`/api/v1/${role}/delivery-costs`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            }),
            params
        });
        return data.result;
    },
    async updateDeliveryCost({
        id,
        formData,
        role
    }: {
        id: string;
        formData: Partial<DeliveryCountry>;
        role: "admin" | "manufacturer";
      
    }): Promise<DeliveryCountry> {
        const params = {
            _fields: deliveryCountryFields
        };
        const { data } = await put(
            `/api/v1/${role}/delivery-costs/${id}`,
            formData,
            {
                params
            }
        );

        return data.result;
    },
    async deleteDeliveryCountry({
        id,
        role
    }: {
        id: string;
        role: "admin" | "manufacturer";
    }): Promise<DeliveryCountry> {
        const { data } = await remove(
            `/api/v1/${role}/delivery-costs/${id}`,
        );
        return data.result;
    },
   
};
