import {
    ProductCollectionDeleteModal,
    PRODUCT_COLLECTION_DELETE_MODAL
} from "./DeleteModal";

export const ProductCollectionModals = () => {
    return (
        <>
            <ProductCollectionDeleteModal
                id={PRODUCT_COLLECTION_DELETE_MODAL}
            />
        </>
    );
};
