import { joiResolver } from "@hookform/resolvers/joi";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import { throwErrorToast } from "../../../../../ui/organisms/Toaster";
import { FetchSelect } from "../../../../../ui/molecules/FetchSelect";
import { useModal } from "../../../../modalpage/hooks";
import { DirectoryAPI } from "../../../../utils/api/requests/directory-requests";
import { useMutation } from "../../../../utils/hooks/useMutation";
import { getAddPlatformSelectionSchemaa } from "../../../../utils/schemas/PlatformSelectionSchema";
import { PromosAPI } from "../../../../utils/api/requests/promos-requests";
import { useHistory } from "react-router-dom";
import { platformSelectionStore } from "../../../store/platformSelectionStore";

interface AddPlatformSelectionModalProps {
    id: string;
}

export const ADD_PLATFORM_SELECTION_MODAL = "ADD_PLATFORM_SELECTION_MODAL";

export const AddPlatformSelectionModal: FC<AddPlatformSelectionModalProps> = observer(
    ({ id }) => {
        const { register, closeModal, activeModalId } = useModal();
        const history = useHistory();
        const { t } = useTranslation();
        const { setCountry } = useContext(platformSelectionStore);
        const form = useForm({
            mode: "onChange",
            resolver: joiResolver(getAddPlatformSelectionSchemaa(t))
        });
        const { handleSubmit, reset, watch } = form;
        const country = watch("country");
        useEffect(() => {
            if (activeModalId !== id) {
                reset({
                    country: undefined
                });
            }
        }, [activeModalId, id, reset]);

        useEffect(() => {
            register({
                id
            });
        }, [id, register]);

        const { mutate, isLoading } = useMutation({
            fetchFn: PromosAPI.getPlatformSelectionList,
            onSuccess: data => {
                if (data.items.length === 0) {
                    setCountry({ _id: country.value, name: country.label });
                    history.push("/platform-selection/create");
                    closeModal();
                } else {
                    throwErrorToast(
                        t("error"),
                        t("platformSelection.errorDescription")
                    );
                }
            },
            onError: () => {
                throwErrorToast(t("error"), t("unknownError"));
            }
        });

        const onSubmit = (values: {
            country: { value: string; label: string };
        }) => {
            mutate({
                countryId: values.country.value
            });
        };

        if (activeModalId !== id) return null;

        return (
            <section className="w-600p bg-white rounded-20p flex flex-col text-dark-main">
                <h4 className="text-18 font-semibold p-6">
                    {t(`platformSelection.addList`)}
                </h4>
                <FormProvider {...form}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FetchSelect
                            fetchFn={DirectoryAPI.getCountries}
                            placeholder={t("chooseCountry")}
                            name="country"
                            label={t("country")}
                            isSearchable={true}
                            className="px-6 mt-6 mb-50p"
                        />
                        <div className="w-full bg-gray-bg p-6 flex rounded-b-20p">
                            <Button text={t("create")} isDisabled={isLoading} />
                            <Button
                                text={t("cancel_2")}
                                type="tertiary"
                                className="ml-4"
                                htmlType="button"
                                onClick={closeModal}
                            />
                        </div>
                    </form>
                </FormProvider>
            </section>
        );
    }
);
