import { joiResolver } from "@hookform/resolvers/joi";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import { MoneyInput } from "../../../../../ui/atoms/MoneyInput";
import { FetchSelect } from "../../../../../ui/molecules/FetchSelect";
import {
    throwErrorToast,
    throwSuccessToast
} from "../../../../../ui/organisms/Toaster";
import { authStore } from "../../../../auth/store/AuthStore";
import { useModal } from "../../../../modalpage/hooks";
import { DeliveryCountry } from "../../../../types";
import { DeliveryCountryAPI } from "../../../../utils/api/requests/delivery-country-requests";
import { DirectoryAPI } from "../../../../utils/api/requests/directory-requests";
import { getDeliveryCountrySchema } from "../../../../utils/schemas/DeliveryCountrySchema";

interface UpdateCountryOfDeliveryModalProps {
    id: string;
}

export const UPDATE_COUNTRY_OF_DELIVERY = "UPDATE_COUNTRY_OF_DELIVERY";

export const UpdateCountryOfDeliveryModal: FC<UpdateCountryOfDeliveryModalProps> = observer(
    ({ id }) => {
        const { t } = useTranslation();
        const { user } = useContext(authStore);
        const {
            register,
            activeModalId,
            closeModal,
            modalData,
            modalCallback
        } = useModal<{
            item: DeliveryCountry;
        }>();
        useEffect(() => {
            register({
                id
            });
        }, [id, register]);

        const formMethods = useForm({
            mode: "onSubmit",
            reValidateMode: "onChange",
            resolver: joiResolver(getDeliveryCountrySchema(t))
        });
        const { reset, watch, handleSubmit } = formMethods;

        useEffect(() => {
            if (id === activeModalId) {
                if (modalData?.item._id) {
                    reset({
                        cost: {
                            value: modalData?.item?.cost.value,
                            currency: modalData?.item?.cost.currency
                        },
                        country: {
                            label: modalData?.item?.country.name,
                            value: modalData?.item?.country._id
                        }
                    });
                } else {
                    reset({
                        cost: { value: 0, currency: "usd" },
                        country: null
                    });
                }
            }
        }, [reset, activeModalId, modalData, id]);

        const [isLoading, setLoading] = useState<boolean>(false);

        const onSubmit = async () => {
            setLoading(true);
            try {
                const data = await DeliveryCountryAPI.updateDeliveryCost({
                    id: modalData?.item.country._id! || watch("country").value,
                    formData: {
                        cost: {
                            value: watch("cost.value"),
                            currency: watch("cost.currency")
                        }
                    },
                    role:
                        user?.role === "manufacturer" ? "manufacturer" : "admin"
                });
                if (data) {
                    closeModal();
                    throwSuccessToast(
                        t(
                            modalData?.item._id
                                ? "deliveryCountry.updated"
                                : "deliveryCountry.added"
                        )
                    );
                    const cb = modalCallback[UPDATE_COUNTRY_OF_DELIVERY];
                    cb && cb(true);
                }
            } catch (error) {
                throwErrorToast(t("error"), t("unknownError"));
            } finally {
                setLoading(false);
            }
        };

        if (activeModalId !== id) return null;
        return (
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section className="w-600p bg-white rounded-20p flex flex-col text-dark-main">
                        <h4 className="text-18 font-semibold p-6 mb-25p">
                            {modalData
                                ? t("deliveryCountry.edit")
                                : t("deliveryCountry.add")}
                        </h4>
                        <section className="flex-col px-25p mb-50p">
                            <FetchSelect
                                fetchFn={DirectoryAPI.getCountries}
                                placeholder={t("chooseCountry")}
                                name={"country"}
                                isEditing={modalData ? false : true}
                                isSearchable={true}
                                label={t("country")}
                                className="mb-30p"
                            />
                            <MoneyInput
                                label={t("price")}
                                name="cost"
                                isEditing={true}
                                placeholder={t("price")}
                                isDisabled={false}
                            />
                        </section>
                        <div className="w-full bg-gray-bg p-6 flex rounded-b-20p ">
                            <Button
                                text={modalData ? t("save") : t("add")}
                                isDisabled={isLoading}
                            />
                            <Button
                                text={t("cancel_2")}
                                type="tertiary"
                                className="ml-10p"
                                htmlType="button"
                                onClick={closeModal}
                                isDisabled={isLoading}
                            />
                        </div>
                    </section>
                </form>
            </FormProvider>
        );
    }
);
