import { PlatformSelection } from "../../types";
import { PlatformSelectionPayload } from "../types";

export const transformPlatformSelection = (
    productCollection: PlatformSelection, isEditing:boolean
): PlatformSelectionPayload => {
    return {
        products: productCollection.products && productCollection.products.length !== 0
            ? productCollection.products.map(p => ({
                  label: p.name,
                  value: p._id,
                  price: { value: p.price?.value, currency: p.price.currency },
                  photo: { url: p.photo?.url  }
              }))
            : isEditing ? [null]  : undefined
    };
};

export const transformPayload = (
    values: Partial<PlatformSelectionPayload>
): Partial<PlatformSelection> => {
    return {
        _id: values._id,
        countryId: values.country?.value,
        productIds: values.products?.map(p => p?.value),
    };
};
