import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import {
    throwErrorToast,
    throwSuccessToast
} from "../../../../../ui/organisms/Toaster";
import { useModal } from "../../../../modalpage/hooks";
import { PromosAPI } from "../../../../utils/api/requests/promos-requests";

interface PublishStoriesModalProps {
    id: string;
}

export const PUBLISH_STORIES_MODAL = "PUBLISH_STORIES_MODAL";

export const PublishStoriesModal: FC<PublishStoriesModalProps> = observer(
    ({ id }) => {
        const {
            register,
            activeModalId,
            closeModal,
            modalData,
            modalCallback
        } = useModal<{
            id: string;
            isPublished: boolean;
        }>();

        const { t } = useTranslation();
        const [isLoading, setLoading] = useState<boolean>(false);

        useEffect(() => {
            register({
                id
            });
        }, [id, register]);

        const onHandle = async () => {
            setLoading(true);
            try {
                const data = await PromosAPI.publishStories({
                    id: modalData?.id!,
                    isPublished: modalData?.isPublished!
                });
                if (data) {
                    closeModal();
                    throwSuccessToast(
                        modalData?.isPublished
                            ? t("stories.storiesUnpublished")
                            : t("stories.storiesPublished")
                    );
                    const cb = modalCallback[PUBLISH_STORIES_MODAL];
                    cb && cb(data);
                }
            } catch (error) {
                throwErrorToast(t("error"), t("unknownError"));
            } finally {
                setLoading(false);
            }
        };

        if (activeModalId !== id) return null;

        return (
            <section className="w-600p bg-white rounded-20p flex flex-col text-dark-main">
                <h4 className="text-18 font-semibold p-6">
                    {modalData?.isPublished
                        ? t(`stories.sureToUnpublishStories`)
                        : t(`stories.sureToPublishStories`)}
                </h4>
                <span className="text-14 leading-5 mb-16 px-6">
                    {modalData?.isPublished
                        ? t(`stories.willBeUnpublish`)
                        : t(`stories.willBePublish`)}
                </span>

                <div className="w-full bg-gray-bg p-6 flex rounded-b-20p">
                    <Button
                        onClick={closeModal}
                        isDisabled={isLoading}
                        text={t("cancel_2")}
                    />
                    <Button
                        text={
                            modalData?.isPublished
                                ? (t(`stories.unpublish`) as string)
                                : (t(`stories.publish`) as string)
                        }
                        type="tertiary"
                        className="ml-2"
                        onClick={onHandle}
                    />
                </div>
            </section>
        );
    }
);
