import { TFunction } from "i18next";
import Joi from "joi";

export const ProductCollectionTaskModalSchema = (t: TFunction) =>
    Joi.object({
        productCollectionName: Joi.string()
            .required()
            .messages({
                "any.required": t("fieldIsRequired"),
                "string.empty": t("fieldIsRequired")
            }),
        description: Joi.string()
            .required()
            .messages({
                "any.required": t("fieldIsRequired"),
                "string.empty": t("fieldIsRequired")
            }),
        countryId: Joi.object({
            label: Joi.string().required(),
            value: Joi.string().required()
        })
            .required()
            .messages({
                "any.required": t("fieldIsRequired"),
                "object.base": t("fieldIsRequired")
            })
            .unknown(true),
        expertId: Joi.object({
            label: Joi.string().required(),
            value: Joi.string().required()
        })
            .required()
            .messages({
                "any.required": t("fieldIsRequired"),
                "object.base": t("fieldIsRequired")
            })
            .unknown(true),
    }).unknown(true);
