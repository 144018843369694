import { TFunction } from "i18next";
import Joi from "joi";

export const getBalanceTopUpSchema = (t: TFunction) =>
    Joi.object<{amount:number}>({
        amount: Joi.number()
        .required()
        .min(1)
        .messages({
            "number.min": t("fieldIsRequired"),
            "any.required": t("fieldIsRequired"),
            "number.empty": t("fieldIsRequired"),
            "number.base": t("fieldIsRequired")
        })
    });
