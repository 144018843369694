import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import { useModal } from "../../../../modalpage/hooks";
import { useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { FetchSelect } from "../../../../../ui/molecules/FetchSelect";
import { UserAPI } from "../../../../utils/api/requests/user-requests";
import {
    throwErrorToast,
    throwSuccessToast
} from "../../../../../ui/organisms/Toaster";
import { PromosAPI } from "../../../../utils/api/requests/promos-requests";
import { useMutation } from "../../../../utils/hooks/useMutation";
import { CatalogueAPI } from "../../../../utils/api/requests/catalogue-requests";
import { joiResolver } from "@hookform/resolvers/joi";
import { TaskPayload } from "../../../../promotion/types";
import { getTaskSchema } from "../../../../promotion/schemas/TaskSchema";

interface SetTaskModalProps {
    id: string;
}

export const SET_TASK_MODAL = "SET_TASK_MODAL";

export const SetTaskModal: FC<SetTaskModalProps> = observer(({ id }) => {
    const {
        register,
        activeModalId,
        closeModal,
        modalData,
        modalCallback
    } = useModal<{
        type: "video" | "review";
        product?: {
            label: string;
            value: string;
        };
    }>();
    const { t } = useTranslation();

    const formMethods = useForm({
        mode: "onChange",
        resolver: joiResolver(getTaskSchema(t))
    });
    const { reset, formState } = formMethods;
    const { isDirty, isValid } = formState;

    useEffect(() => {
        register({
            id
        });
    }, [id, register]);

    useEffect(() => {
        if (activeModalId !== id) {
            reset({});
        } else {
            if (modalData?.product) {
                reset({ productId: modalData?.product });
            }
        }
    }, [activeModalId, id, modalData, modalData?.product, reset]);

    const { mutate, isLoading } = useMutation({
        fetchFn: PromosAPI.createTask,
        onSuccess: () => {
            throwSuccessToast(t("taskCreated"), t("taskCreated"));
            const cb = modalCallback[SET_TASK_MODAL];
            cb && cb(true);
            closeModal();
        },
        onError: () => throwErrorToast(t("error"), t("unknownError"))
    });

    const onSubmit = (payload: TaskPayload) => {
        mutate({
            productId: payload.productId.value,
            expertId: payload.expertId.value,
            type: modalData?.type
        });
    };

    if (activeModalId !== id) return null;

    return (
        <FormProvider {...formMethods}>
            <form
                className="w-600p bg-white rounded-20p flex flex-col text-dark-main"
                onSubmit={formMethods.handleSubmit(onSubmit)}
            >
                <h4 className="text-18 font-semibold p-6 mb-25p">
                    {t(`taskCreating`)}:{" "}
                    {modalData?.type === "video"
                        ? t("video.plural_0").toLocaleLowerCase()
                        : t("review.plural_0").toLocaleLowerCase()}
                </h4>
                <section className="grid gap-30p px-25p pb-50p">
                    <FetchSelect
                        fetchFn={CatalogueAPI.getList}
                        placeholder={t("chooseWineName")}
                        label={t("chooseWine")}
                        name="productId"
                        extraArgs={{ isDeleted: false }}
                        isSearchable={true}
                        isDisabled={!!modalData?.product}
                    />
                    <FetchSelect
                        fetchFn={UserAPI.getList}
                        placeholder={t("choosePerformer")}
                        label={t("performer")}
                        isSearchable={true}
                        name="expertId"
                        extraArgs={{ role: "expert" }}
                    />
                </section>
                <div className="w-full bg-gray-bg p-6 flex rounded-b-20p">
                    <Button
                        isDisabled={isLoading || !isValid || !isDirty}
                        text={t("setTask")}
                    />
                    <Button
                        text={t("cancel_2")}
                        onClick={closeModal}
                        className="ml-4"
                        htmlType="button"
                        type="tertiary"
                    />
                </div>
            </form>
        </FormProvider>
    );
});
