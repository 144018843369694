import { SUPPORTED_LANGUAGES } from "../utils/i18n";
import reduceFields, { reduceList } from "../utils/reduceList";

const fields = [
    { name: ["default"] },
    { photo: ["url"] },
    {
        manufacturer: [
            "name",
            {
                country: ["name"]
            }
        ]
    },
    { region: ["name", { country: ["name"] }] },
    { price: ["value", "currency"] },
    { newPrice: ["value", "currency"] },
    "qvinoRating",
    "isAvailableForSale",
    "isPromoted",
    "stockCount",
    "sold",
    "bonusPoints",
    "isSoldByQvino",
    "showCount",
    "claimHashCount",
    "failHashCount",
    "totalSoldCount",
    "createdHashCount",
    "createDate",
    "deleteDate",
    { deletedBy: ["name"] }
];
const detailsFields = [
    { name: ["default"] },
    { photo: ["url"] },
    {
        manufacturer: ["name"]
    },
    {
        region: [
            "name",
            {
                country: ["name"]
            }
        ]
    },
    { price: ["value", "currency"] },
    { newPrice: ["value", "currency"] },
    "qvinoRating",
    "wineType",
    "vintage",
    "alcoholLevel",
    "volume",
    { description: SUPPORTED_LANGUAGES },
    {
        grapeSorts: ["name"]
    },
    "gastronomies",
    "bonusPoints",
    "videoUrl",
    "awardYear",
    { wineStyle: ["name"] },
    "agencyRatings",
    "isDeleted",
    "isAvailableForSale",
    "stockCount",
    "saleStatus",
    "videoId",
    "expertReviewId",
    { expertReview: [{ user: ["name"] }, "createDate"] },
    { video: [{ expert: ["name"] }, "createDate"] },
    "hasDoubleBonusPoints",
    "discountEndDate",
    "isPromoted",
    "agingPotential",
    "recommendedYear",
    "altitude",
    "taste",
    "isSoldByQvino",
    "isLimited",
    "limitedCount",
    "wineGroup"
];

export const productFields = reduceList(fields);
export const productDetailsFields = reduceList(detailsFields);

export const productListFields = reduceFields({
    items: fields
});
