import { FC, useState } from "react";
import cn from "classnames";

interface ImageProps {
    Placeholder: React.ElementType;
    photoUrl?: string;
    alt: string | undefined;
}

export const Image: FC<ImageProps> = ({ photoUrl, Placeholder, alt }) => {
    const [error, setError] = useState(false);

    const classes = cn(
        "relative inline-flex flex-shrink-0 max-w-220p max-h-310p rounded-20p"
    );
    const imgClasses = cn("w-full h-full rounded-20p object-contain");

    const containerClasses = cn(
        "bg-gray-bg overflow-hidden w-full h-full rounded-20p"
    );

    const placeholderClasses = cn(
        "w-full h-full border-gray-light border-2 rounded-20p"
    );

    const onLoad = () => {
        setError(false);
    };

    const onError = () => {
        setError(true);
    };

    return (
        <div className={classes}>
            {photoUrl && !error ? (
                <div className={containerClasses}>
                    <img
                        className={imgClasses}
                        src={photoUrl}
                        alt={alt}
                        onError={onError}
                        onLoad={onLoad}
                    />
                </div>
            ) : (
                <Placeholder className={placeholderClasses} />
            )}
        </div>
    );
};
