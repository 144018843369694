import { joiResolver } from "@hookform/resolvers/joi";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import { FormInput } from "../../../../../ui/atoms/FormInput";
import {
    throwErrorToast,
    throwSuccessToast
} from "../../../../../ui/organisms/Toaster";
import { useModal } from "../../../../modalpage/hooks";
import { Product } from "../../../../types";
import { CatalogueAPI } from "../../../../utils/api/requests/catalogue-requests";
import { useMutation } from "../../../../utils/hooks/useMutation";
import { VideoUpload } from "../../../../../ui/atoms/VideoUpload";
import { getARSchema } from "../../../../utils/schemas/ARSchema";
import { WinePlaceholder } from "../../../../../ui/atoms/illustration";
import { ImageUpload } from "../../atoms/ImageUpload";
import { catalogueStore } from "../../../store/CatalogueStore";
import ARInfoImage from "../../../../../assets/illustration/ar-info-image.jpg";
import { AnimatePresence, motion } from "framer-motion";
import { InfoButton } from "../../../../../ui/atoms/InfoButton";

const email = process.env.REACT_APP_AR_LOGIN;
const password = process.env.REACT_APP_AR_PASSWORD;
const project = process.env.REACT_APP_AR_PROJECT;
const categoryID = process.env.REACT_APP_AR_CATEGORYID;

interface ARModalProps {
    id: string;
}

export const AR_MODAL = "AR_MODAL";

export interface ARModalFormData {
    sideLength: number;
    sideWidth: number;
    topDiameter: number;
    bottomDiameter: number;
    videoProduction: File;
    videoSommelier: File;
    image: File;
}
export const ARModal: FC<ARModalProps> = observer(({ id }) => {
    const { t } = useTranslation();
    const { product } = useContext(catalogueStore);
    const { closeModal, activeModalId } = useModal<{
        item: Product;
    }>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [token, setToken] = useState<string | null>(null);
    const [isOpened, setOpened] = useState<boolean>(false);

    const handleOpened = () => {
        setOpened(prev => !prev);
    };

    const { mutate } = useMutation({
        fetchFn: CatalogueAPI.loginAR,
        onSuccess: data => {
            setToken(data.token);
        },
        onError: () => throwErrorToast(t("error"), t("ar.serviceUnavailable"))
    });

    useEffect(() => {
        if (activeModalId === id) {
            mutate({
                email: email!,
                password: password!
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeModalId, id]);

    const form = useForm<ARModalFormData>({
        mode: "onChange",
        resolver: joiResolver(getARSchema(t))
    });

    const { handleSubmit, reset } = form;

    useEffect(() => {
        if (activeModalId !== id) {
            reset();
        }
    }, [activeModalId, id, reset]);

    const onSubmit = async (values: ARModalFormData) => {
        setLoading(true);
        try {
            let imageLink = undefined;
            let videoProductionLink = undefined;
            let videoSommelierLink = undefined;
            if (values?.image instanceof File) {
                const { filename } = await CatalogueAPI.uploadFileToAR({
                    file: values.image,
                    token: token!
                });
                imageLink = filename;
            }
            if (values?.videoProduction instanceof File) {
                const { filename } = await CatalogueAPI.uploadFileToAR({
                    file: values.videoProduction,
                    token: token!
                });
                videoProductionLink = filename;
            }
            if (values?.videoSommelier instanceof File) {
                const { filename } = await CatalogueAPI.uploadFileToAR({
                    file: values.videoSommelier,
                    token: token!
                });
                videoSommelierLink = filename;
            }
            const data = await CatalogueAPI.addARTarget({
                _id: product?._id!,
                name: product?.name.default!,
                sideWidth: values.sideWidth / 100,
                sideLength: values.sideLength / 100,
                topDiameter: values.topDiameter / 100,
                bottomDiameter: values.bottomDiameter / 100,
                imageLink: imageLink!,
                videoProductionLink: videoProductionLink,
                videoSommelierLink: videoSommelierLink,
                token: token!,
                project: project!,
                categoryID: categoryID!
            });
            if (data) {
                closeModal();
                throwSuccessToast(t("ar.successCreated"));
            }
        } catch {
            throwErrorToast(t("error"), t("unknownError"));
        } finally {
            setLoading(false);
        }
    };

    if (activeModalId !== id) return null;

    return (
        <>
            {!isOpened ? (
                <section className="w-600p bg-white rounded-20p flex flex-col text-dark-main">
                    <div className="flex justify-between items-center mr-5">
                        <h4 className="text-18 font-semibold p-6">
                            {t("ar.modalHeader")}
                        </h4>
                        <InfoButton setOpened={handleOpened} />
                    </div>
                    <FormProvider {...form}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ImageUpload
                                EmptyState={WinePlaceholder}
                                name={"image"}
                                alt={"image.alt"}
                            />
                            <section className="grid grid-cols-4 gap-2 px-6">
                                <FormInput
                                    name="sideLength"
                                    label={t("ar.sideLength")}
                                    isMaskedNumber={true}
                                    multiplyingKoef={10}
                                    decimalScale={1}
                                    allowNegative={false}
                                    className="mt-6"
                                />
                                <FormInput
                                    name="sideWidth"
                                    label={t("ar.sideWidth")}
                                    isMaskedNumber={true}
                                    decimalScale={1}
                                    multiplyingKoef={10}
                                    allowNegative={false}
                                    className="mt-6"
                                />
                                <FormInput
                                    name="topDiameter"
                                    label={t("ar.topDiameter")}
                                    isMaskedNumber={true}
                                    decimalScale={1}
                                    multiplyingKoef={10}
                                    allowNegative={false}
                                    className="mt-6"
                                />
                                <FormInput
                                    name="bottomDiameter"
                                    label={t("ar.bottomDiameter")}
                                    isMaskedNumber={true}
                                    decimalScale={1}
                                    multiplyingKoef={10}
                                    allowNegative={false}
                                    className="mt-6"
                                />
                            </section>
                            <section className="grid grid-cols">
                                <div className="px-6 mt-6">
                                    <div className="text-14 text-gray-text font-semibold mb-3">
                                        {t("ar.videoProduction")}
                                    </div>
                                    <VideoUpload
                                        name="videoProduction"
                                        isEditing={true}
                                        className="whitespace-nowrap"
                                    />
                                </div>
                                <div className="mt-6 px-6">
                                    <div className="text-14 text-gray-text font-semibold mb-3">
                                        {t("ar.videoSommelier")}
                                    </div>
                                    <VideoUpload
                                        name="videoSommelier"
                                        isEditing={true}
                                        className="whitespace-nowrap"
                                    />
                                </div>
                            </section>

                            <div className="w-full bg-gray-bg p-6 flex rounded-b-20p mt-6">
                                <Button
                                    text={t("send")}
                                    isDisabled={isLoading || !token}
                                />
                                <Button
                                    text={t("cancel_2")}
                                    type="tertiary"
                                    className="ml-4"
                                    htmlType="button"
                                    onClick={closeModal}
                                />
                            </div>
                        </form>
                    </FormProvider>
                </section>
            ) : (
                <AnimatePresence>
                    <motion.div
                        className="z-100"
                        onClick={event => event.stopPropagation()}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.1 }}
                    >
                        <div className="flex relative items-center justify-center inline-flex flex-shrink-0 rounded-20p mt-5 h-620p">
                            <img
                                className={
                                    "w-full h-full rounded-20p object-contain"
                                }
                                src={ARInfoImage}
                                alt={ARInfoImage}
                            />
                            <div className="absolute top-5 right-5">
                                <InfoButton
                                    setOpened={handleOpened}
                                    symbol={"x"}
                                />
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>
            )}
        </>
    );
});
