import { joiResolver } from "@hookform/resolvers/joi";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import { WinePlaceholder } from "../../../../../ui/atoms/illustration";
import { FetchSelect } from "../../../../../ui/molecules/FetchSelect";
import {
    throwErrorToast,
    throwSuccessToast
} from "../../../../../ui/organisms/Toaster";
import { useModal } from "../../../../modalpage/hooks";
import { LabelRecognition } from "../../../../types";
import { PromosAPI } from "../../../../utils/api/requests/promos-requests";
import { getLabelRecognitionSchema } from "../../../../utils/schemas/LabelRecognitionSchema";
import { Image } from "../../atoms/Image";

interface LabelRecognitionModalModalsProps {
    id: string;
}

export const LABEL_RECOGNITION_MODAL = "LABEL_RECOGNITION_MODAL";

export const LabelRecognitionModal: FC<LabelRecognitionModalModalsProps> = observer(
    ({ id }) => {
        const { t } = useTranslation();
        const {
            register,
            activeModalId,
            closeModal,
            modalData,
            modalCallback
        } = useModal<{
            row: LabelRecognition;
        }>();

        useEffect(() => {
            register({
                id
            });
        }, [id, register]);

        const formMethods = useForm({
            mode: "onSubmit",
            reValidateMode: "onChange",
            resolver: joiResolver(getLabelRecognitionSchema(t))
        });
        const { reset, watch, handleSubmit } = formMethods;

        useEffect(() => {
            reset({
                products: null
            });
        }, [reset, activeModalId]);
        const [isLoading, setLoading] = useState<boolean>(false);

        const onDelete = async () => {
            setLoading(true);
            try {
                const data = await PromosAPI.deleteUnrecognizedLabel({
                    id: modalData?.row._id!
                });
                if (data) {
                    setLoading(false);
                    closeModal();
                    throwSuccessToast(
                        t("labelRecognition.plural_1"),
                        t("labelRecognition.successNotRecognizable")
                    );
                    const cb = modalCallback[LABEL_RECOGNITION_MODAL];
                    cb && cb(true);
                }
            } catch (error) {
                throwErrorToast(t("error"), t("unknownError"));
            } finally {
                setLoading(false);
            }
        };

        const onSubmit = async () => {
            setLoading(true);
            try {
                const data = await PromosAPI.linkToProductUnrecognizedLabel({
                    id: modalData?.row._id!,
                    productId: watch("product").value
                });
                if (data) {
                    setLoading(false);
                    closeModal();
                    throwSuccessToast(
                        t("labelRecognition.plural_1"),
                        t("labelRecognition.successLinktoProduct")
                    );
                    const cb = modalCallback[LABEL_RECOGNITION_MODAL];
                    cb && cb(true);
                }
            } catch (error) {
                throwErrorToast(t("error"), t("unknownError"));
            } finally {
                setLoading(false);
            }
        };

        if (activeModalId !== id) return null;
        return (
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section className="w-600p bg-white rounded-20p flex flex-col text-dark-main">
                        <h4 className="text-18 font-semibold p-6">
                            {t("labelRecognition.wineRecognition")}
                        </h4>
                        <section className="flex-col px-25p mb-50p">
                            <div className="flex items-center justify-center my-25p">
                                <Image
                                    Placeholder={WinePlaceholder}
                                    alt={modalData?.row._id}
                                    photoUrl={modalData?.row.file!.url}
                                />
                            </div>
                            <FetchSelect
                                fetchFn={PromosAPI.getProductList}
                                placeholder={t(
                                    "productCollections.winePlaceholder"
                                )}
                                name={"product"}
                                isEditing={true}
                                isSearchable={true}
                                label={t("wineName")}
                            />
                        </section>

                        <div className="w-full bg-gray-bg p-6 flex rounded-b-20p justify-between">
                            <div className="flex">
                                <Button
                                    text={t("choose")}
                                    isDisabled={isLoading}
                                />
                                <Button
                                    text={t("cancel_2")}
                                    type="tertiary"
                                    className="ml-4"
                                    htmlType="button"
                                    onClick={closeModal}
                                    isDisabled={isLoading}
                                />
                            </div>
                            <Button
                                text={t("labelRecognition.wineNotRecognizable")}
                                type="secondary-danger"
                                className="ml-4"
                                htmlType="button"
                                onClick={onDelete}
                                isDisabled={isLoading}
                            />
                        </div>
                    </section>
                </form>
            </FormProvider>
        );
    }
);
