import { BalanceTopUpModal, BALANCE_TOP_UP_MODAL } from "./BalanceTopUpModal";
import { DELETE_EVENT_MODAL, DeleteEventModal } from "../../../events/ui/modals/DeleteEventModal/DeleteEventModal";
import { DeleteModal, DIRECTORY_DELETE_MODAL } from "./DeleteModal";
import { DeleteRelatedManufacturerModal, DELETE_RELATED_MANUFACTURER_MODAL } from "./DeleteRelatedManufacturerModal";
import { GrapeSortModal, GRAPESORT_EDIT_MODAL } from "./GrapeSortModal";
import {
    ManufacturerModal,
    MANUFACTURER_EDIT_MODAL
} from "./ManufacturerModal";
import { RegionModal, REGION_EDIT_MODAL } from "./RegionModal";
import { RelatedManufacturerModal, MANUFACTURER_RELATED_ADD_MODAL } from "./RelatedManufacturerModal";
import { RetailerModal, RETAILER_EDIT_MODAL } from "./RetailerModal";
import { WineStyleModal, WINESTYLE_EDIT_MODAL } from "./WineStyleModal";

export const DirectoryModals = () => {
    return (
        <>
            <DeleteModal id={DIRECTORY_DELETE_MODAL} />
            <ManufacturerModal id={MANUFACTURER_EDIT_MODAL} />
            <DeleteRelatedManufacturerModal id={DELETE_RELATED_MANUFACTURER_MODAL} />
            <RelatedManufacturerModal id={MANUFACTURER_RELATED_ADD_MODAL}/>
            <DeleteEventModal id={DELETE_EVENT_MODAL}/>
            <GrapeSortModal id={GRAPESORT_EDIT_MODAL} />
            <RegionModal id={REGION_EDIT_MODAL} />
            <WineStyleModal id={WINESTYLE_EDIT_MODAL} />
            <RetailerModal id={RETAILER_EDIT_MODAL} />
            <BalanceTopUpModal id={BALANCE_TOP_UP_MODAL} />
        </>
    );
};
