import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import { useModal } from "../../../../modalpage/hooks";
import { useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { FetchSelect } from "../../../../../ui/molecules/FetchSelect";
import { UserAPI } from "../../../../utils/api/requests/user-requests";
import {
    throwErrorToast,
    throwSuccessToast
} from "../../../../../ui/organisms/Toaster";
import { PromosAPI } from "../../../../utils/api/requests/promos-requests";
import { useMutation } from "../../../../utils/hooks/useMutation";
import { joiResolver } from "@hookform/resolvers/joi";
import { TaskPayload } from "../../../../promotion/types";
import { DirectoryAPI } from "../../../../utils/api/requests/directory-requests";
import { FormInput } from "../../../../../ui/atoms/FormInput";
import { FormTextarea } from "../../../../../ui/atoms/FormTextarea";
import { ProductCollectionTaskModalSchema } from "../../../../utils/schemas/ProductCollectionTaskModalSchema";

interface ProductCollectionModalProps {
    id: string;
}

export const PRODUCT_COLLECTION_TASK_MODAL = "PRODUCT_COLLECTION_TASK_MODAL";

export const ProductCollectionModal: FC<ProductCollectionModalProps> = observer(
    ({ id }) => {
        const {
            register,
            activeModalId,
            closeModal,
            modalCallback
        } = useModal();
        const { t } = useTranslation();

        const formMethods = useForm({
            mode: "onChange",
            resolver: joiResolver(ProductCollectionTaskModalSchema(t))
        });
        const { reset } = formMethods;

        useEffect(() => {
            register({
                id
            });
        }, [id, register]);

        useEffect(() => {
            if (activeModalId !== id) {
                reset();
            }
        }, [activeModalId, id, reset]);

        const { mutate, isLoading } = useMutation({
            fetchFn: PromosAPI.createTask,
            onSuccess: () => {
                throwSuccessToast(t("taskCreated"), t("taskCreated"));
                const cb = modalCallback[PRODUCT_COLLECTION_TASK_MODAL];
                cb && cb(true);
                closeModal();
            },
            onError: () => throwErrorToast(t("error"), t("unknownError"))
        });

        const onSubmit = (payload: TaskPayload) => {
            mutate({
                expertId: payload.expertId.value,
                type: "productCollection",
                description: payload.description,
                productCollectionName: payload.productCollectionName,
                countryId: payload.countryId?.value
            });
        };

        if (activeModalId !== id) return null;

        return (
            <FormProvider {...formMethods}>
                <form
                    className="w-600p bg-white rounded-20p flex flex-col text-dark-main"
                    onSubmit={formMethods.handleSubmit(onSubmit)}
                >
                    <h4 className="text-18 font-semibold p-6 mb-25p">
                        {t(`taskCreating`)}:{" "}
                        {t("productCollection.plural_0").toLocaleLowerCase()}
                    </h4>
                    <section className="grid gap-30p px-25p pb-50p">
                        <FetchSelect
                            fetchFn={DirectoryAPI.getCountries}
                            placeholder={t("chooseCountry")}
                            name="countryId"
                            label={t("productCollections.showCountry")}
                            isSearchable={true}
                            isEditing={true}
                        />
                        <FormInput
                            name="productCollectionName"
                            label={
                                t("label") +
                                " " +
                                t(
                                    "productCollection.plural_1"
                                ).toLocaleLowerCase()
                            }
                            isEditing={true}
                            defaultValue=""
                            maxLength={120}
                        />
                        <FormTextarea
                            name="description"
                            textareaClasses="min-h-120p resize-none"
                            label={t("description")}
                            defaultValue=""
                            isEditing={true}
                            maxLength={600}
                        />
                        <FetchSelect
                            fetchFn={UserAPI.getList}
                            placeholder={t("choosePerformer")}
                            label={t("performer")}
                            isSearchable={true}
                            name="expertId"
                            extraArgs={{ role: "expert" }}
                        />
                    </section>
                    <div className="w-full bg-gray-bg p-6 flex rounded-b-20p">
                        <Button isDisabled={isLoading} text={t("setTask")} />
                        <Button
                            text={t("cancel_2")}
                            onClick={closeModal}
                            className="ml-4"
                            htmlType="button"
                            type="tertiary"
                        />
                    </div>
                </form>
            </FormProvider>
        );
    }
);
