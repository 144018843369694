import { TFunction } from "i18next";
import Joi from "joi";

import { ARModalFormData } from "../../catalogue/ui/modals/ARModal";

export const getARSchema = (t: TFunction) =>
    Joi.object<ARModalFormData>({
        sideLength: Joi.number()
            .required()
            .messages({
                "number.empty": t("fieldIsRequired"),
                "number.base": t("fieldIsRequired"),
                "any.required": t("fieldIsRequired")
            }),
        sideWidth: Joi.number()
            .required()
            .messages({
                "number.empty": t("fieldIsRequired"),
                "number.base": t("fieldIsRequired"),
                "any.required": t("fieldIsRequired")
            }),
        topDiameter: Joi.number()
            .required()
            .messages({
                "number.empty": t("fieldIsRequired"),
                "number.base": t("fieldIsRequired"),
                "any.required": t("fieldIsRequired")
            }),
        bottomDiameter: Joi.number()
            .required()
            .messages({
                "number.empty": t("fieldIsRequired"),
                "number.base": t("fieldIsRequired"),
                "any.required": t("fieldIsRequired")
            }),
        image: Joi.object().required().unknown(true)
    }).unknown(true);
