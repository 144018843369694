import { joiResolver } from "@hookform/resolvers/joi";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import { FormInput } from "../../../../../ui/atoms/FormInput";
import { BalancePointsIcon } from "../../../../../ui/atoms/Icon";
import {
    throwErrorToast,
    throwSuccessToast
} from "../../../../../ui/organisms/Toaster";
import { useModal } from "../../../../modalpage/hooks";
import { Manufacturer } from "../../../../types";
import { DirectoryAPI } from "../../../../utils/api/requests/directory-requests";
import { useMutation } from "../../../../utils/hooks/useMutation";
import { getBalanceTopUpSchema } from "../../../../utils/schemas/BalanceTopUpSchema";

interface BalanceTopUpModalProps {
    id: string;
}

export const BALANCE_TOP_UP_MODAL = "BALANCE_TOP_UP_MODAL";

export const BalanceTopUpModal: FC<BalanceTopUpModalProps> = observer(
    ({ id }) => {
        const {
            register,
            closeModal,
            activeModalId,
            modalData,
            modalCallback
        } = useModal<{
            item: Manufacturer;
        }>();
        const { t } = useTranslation();
        const form = useForm<{ amount: number }>({
            mode: "onChange",
            resolver: joiResolver(getBalanceTopUpSchema(t))
        });
        const { handleSubmit, reset } = form;
        useEffect(() => {
            if (activeModalId !== id) {
                reset({
                    amount: 0
                });
            }
        }, [activeModalId, id, reset]);

        useEffect(() => {
            register({
                id
            });
        }, [id, register]);

        const { mutate, isLoading } = useMutation({
            fetchFn: DirectoryAPI.BalanceTopUp,
            onSuccess: data => {
                closeModal();
                const cb = modalCallback[id];
                cb && cb(true, data);
                throwSuccessToast(t(`balanceTopUp.pointsAwarded`));
            },
            onError: () => {
                throwErrorToast(t("error"), t("unknownError"));
            }
        });

        const onSubmit = (values: { amount: number }) => {
            mutate({
                amount: values.amount,
                _id: modalData?.item?._id!
            });
        };

        if (activeModalId !== id) return null;

        return (
            <FormProvider {...form}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-600p bg-white rounded-20p flex flex-col text-dark-main"
                >
                    <h4 className="text-18 font-semibold p-6">
                        {t("balanceTopUp.balanceTopUp") +
                            ": " +
                            modalData?.item?.name}
                    </h4>
                    <span className="text-16 font-semibold px-6 mt-6 flex items-center">
                        {t("balanceTopUp.balance") +
                            ": " +
                            (modalData?.item?.balances?.points || 0)}
                        <BalancePointsIcon className="ml-6p" />
                    </span>
                    <FormInput
                        isMaskedNumber
                        label={t("balanceTopUp.accrualAmount")}
                        name="amount"
                        placeholder={t("scores")}
                        className="mt-6 px-6 mb-12 w-full"
                        maxLength={16}
                        decimalScale={0}
                        allowNegative={false}
                    />
                    <div className="w-full bg-gray-bg p-6 flex rounded-b-20p">
                        <Button
                            text={t("balanceTopUp.popUp")}
                            isDisabled={isLoading}
                        />
                        <Button
                            text={t("cancel_2")}
                            type="tertiary"
                            className="ml-4"
                            htmlType="button"
                            onClick={closeModal}
                        />
                    </div>
                </form>
            </FormProvider>
        );
    }
);
