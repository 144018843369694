import { joiResolver } from "@hookform/resolvers/joi";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import { FormInput } from "../../../../../ui/atoms/FormInput";
import { ManufacturePlaceholder } from "../../../../../ui/atoms/illustration";
import { ImageUpload } from "../../../../../ui/atoms/ImageUpload";
import { OptionType } from "../../../../../ui/atoms/Select";
import { throwErrorToast, throwSuccessToast } from "../../../../../ui/organisms/Toaster";
import { FetchSelect } from "../../../../../ui/molecules/FetchSelect";
import { getSafariFriendlyDate } from "../../../../catalogue/utils/getSafariFriendlyDate";
import { FileType } from "../../../../types";
import { DirectoryAPI } from "../../../../utils/api/requests/directory-requests";
import { uploadFile } from "../../../../utils/api/requests/file-requests";
import { useMutation } from "../../../../utils/hooks/useMutation";
import { getManufacturerSchema } from "../../../../utils/schemas/ManufacturerSchema";
import { ArticleSelect } from "../../molecules/ArticleSelect";
import { modalPageStore } from "../../../../modalpage/store/ModalPageStore";

interface DeleteModalProps {
    id: string;
}

export const MANUFACTURER_EDIT_MODAL = "MANUFACTURER_EDIT_MODAL";

interface ManufacturerPayload {
    name: string;
    country: OptionType;
    logoId: string | null;
    logo: FileType | File | null;
    articles: OptionType;
    weight: number;
}

export const ManufacturerModal: FC<DeleteModalProps> = observer(({ id }) => {
    const {
        register,
        closeModal,
        activeModalId,
        modalData,
        modalCallback,
        isPrev
    } = useContext(modalPageStore);

    const { t } = useTranslation();
    const form = useForm<ManufacturerPayload>({
        mode: "onChange",
        resolver: joiResolver(getManufacturerSchema(t))
    });
    const { handleSubmit, setValue, formState, reset, watch } = form;
    const countryKey = watch("country")?.toString() + "country";
    const articlesKey = watch("articles")?.toString() + "articles";

    useEffect(() => {
        register({
            id
        });
    }, [id, register]);

    const { isDirty, isValid } = formState;

    useEffect(() => {
        if (!isPrev) {
            if (modalData && modalData.item) {
                reset({
                    logo: modalData.item?.logo,
                    logoId: modalData.item?.logoId,
                    name: modalData.item.name,
                    weight: modalData.item.weight,
                    ...(modalData.item.country
                        ? {
                              country: {
                                  label: modalData.item.country.name,
                                  value: modalData.item.country._id
                              }
                          }
                        : {}),
                    ...(modalData.item.article
                        ? {
                              articles: {
                                  label: `${modalData.item.name} (${getSafariFriendlyDate(modalData.item.article.createDate).format("DD.MM.YYYY")})`,
                                  value: modalData.item.article._id
                              }
                          }
                        : {})
                });
            } else {
                reset({});
            }
        }
    }, [isPrev, modalData, reset, setValue]);

    const { mutate, isLoading } = useMutation({
        fetchFn: async (values: ManufacturerPayload) => {
            let logoId = values.logoId || null;
            if (values?.logo instanceof File) {
                const { _id } = await uploadFile(values?.logo);
                logoId = _id;
            }
            const formData = {
                countryId: values.country.value,
                articleId: values?.articles?.value,
                weight: Number(values.weight),
                name: {
                    default: values.name
                },
                _id: modalData?.item?._id ?? undefined,
                logoId: logoId ?? modalData?.item?.logo?._id
            };
            return modalData?.item?._id
                ? DirectoryAPI.updateManufacturer(formData)
                : DirectoryAPI.createManufacturer(formData);
        },
        onSuccess: data => {
            closeModal();
            const cb = modalCallback[id];
            cb && cb(true, data);
            if (modalData?.item?._id) {
                throwSuccessToast(
                    t(`changesSaved`),
                    t(`manufacturer.changesSaved`)
                );
            } else {
                throwSuccessToast(
                    t(`manufacturer.successTitle`),
                    t(`manufacturer.successDescription`)
                );
            }
        },
        onError: error => {
            if (
                error.response?.data.error.data?.issues?.length &&
                error.response?.data.error.data?.issues[0]?.rule === "uniqLangMap"
            ) {
                throwErrorToast(
                    t("error"),
                    t(`manufacturer.manufacturerNotUnique`)
                );
            } else {
                throwErrorToast(t("error"), t("unknownError"));
            }
        }
    });

    const onSubmit = async (values: ManufacturerPayload) => {
        await mutate(values);
    };

    if (activeModalId !== id) return null;

    return (
        <FormProvider {...form}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="max-w-full h-full bg-white rounded-20p flex flex-col text-dark-main overflow-y-auto"
                style={{ maxHeight: "90vh" }}
            >
                <h4 className="text-18 font-semibold p-6">
                    {modalData?.item?._id
                        ? `${t("editing")} ${t("manufacturer.plural_2").toLowerCase()}`
                        : t(`manufacturer.addTitle`)}
                </h4>
                <ImageUpload
                    EmptyState={ManufacturePlaceholder}
                    name="logo"
                    alt={modalData?.item?.name}
                    className="px-25p mt-25p"
                />
                <div key={countryKey}>
                    <FetchSelect
                        fetchFn={DirectoryAPI.getCountries}
                        placeholder={t("chooseCountry")}
                        name="country"
                        label={t("country")}
                        isSearchable={true}
                        className="px-6"
                    />
                </div>
                <FormInput
                    name="name"
                    label={t("manufacturer.plural_0")}
                    placeholder={t("manufacturer.enterName")}
                    className="mt-30p px-6 w-full"
                />
                <FormInput
                    name="weight"
                    label="weight"
                    placeholder="0"
                    type="text"
                    className="mt-30p px-6 w-full"
                />
                <ArticleSelect
                    disabled={!!modalData?.item?._id}
                    label={t("manufacturerArticle")}
                    id={modalData?.item?._id}
                    className="mt-30p"
                    key={articlesKey}
                />

                <div className="w-full bg-gray-bg p-6 flex rounded-b-20p">
                    <Button
                        text={modalData?.item?._id ? t("save") : t("create")}
                        isDisabled={!isValid || !isDirty || isLoading}
                    />
                    <Button
                        text={t("cancel_2")}
                        type="tertiary"
                        className="ml-4"
                        htmlType="button"
                        onClick={closeModal}
                    />
                </div>
            </form>
        </FormProvider>
    );
});
