import {
    DeleteSalepointModal,
    DELETE_SALEPOINT_MODAL
} from "./DeleteSalepoint";

export const SalepointsModals = () => {
    return (
        <>
            <DeleteSalepointModal id={DELETE_SALEPOINT_MODAL} />
        </>
    );
};
