import { joiResolver } from "@hookform/resolvers/joi";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import { Input } from "../../../../../ui/atoms/Input";
import { throwErrorToast, throwSuccessToast } from "../../../../../ui/organisms/Toaster";
import { useModal } from "../../../../modalpage/hooks";
import { MobileVersion } from "../../../../types";
import { SettingsAPI } from "../../../../utils/api/requests/settings-requests";
import { getMobileVersionSchema } from "../../../../utils/schemas/MobileVersionSchema";

interface UpdateMobileVersionModalProps {
    id: string;
}

export const UPDATE_MOBILE_VERSION = "UPDATE_MOBILE_VERSION";

export const UpdateMobileVersionModal: FC<UpdateMobileVersionModalProps> = observer(
    ({ id }) => {
        const { t } = useTranslation();
        const { register, activeModalId, closeModal, modalData, modalCallback } = useModal<{
            item: MobileVersion;
        }>();

        useEffect(() => {
            register({ id });
        }, [id, register]);

        const formMethods = useForm<MobileVersion>({
            mode: "onSubmit",
            reValidateMode: "onChange",
            resolver: joiResolver(getMobileVersionSchema(t))
        });
        const { reset, handleSubmit } = formMethods;

        useEffect(() => {
            if (id === activeModalId && modalData?.item) {
                reset({ currentVersions: modalData.item.currentVersions });
            }
        }, [reset, activeModalId, modalData, id]);

        const [isLoading, setLoading] = useState<boolean>(false);

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            if (/^[0-9.]*$/.test(value)) {
                formMethods.setValue("currentVersions", value);
            }
        };

        const onSubmit = async (data: MobileVersion) => {
            setLoading(true);
            try {
                let result;
                if (modalData?.item._id) {
                    result = await SettingsAPI.updateMobileVersion({
                        id: modalData.item._id,
                        formData: data
                    });
                } else {
                    result = await SettingsAPI.createMobileVersion(data);
                }
                if (result) {
                    closeModal();
                    throwSuccessToast(
                        t(modalData?.item._id ? "mobileVersion.updated" : "mobileVersion.added")
                    );
                    const cb = modalCallback[UPDATE_MOBILE_VERSION];
                    cb && cb(true);

                    reset({ currentVersions: "" });
                }
            } catch (error) {
                throwErrorToast(t("error"), t("unknownError"));
            } finally {
                setLoading(false);
            }
        };

        if (activeModalId !== id) return null;
        return (
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section className="w-600p bg-white rounded-20p flex flex-col text-dark-main">
                        <h4 className="text-18 font-semibold p-6 mb-25p">
                            {modalData?.item._id ? t("mobileVersion.edit") : t("mobileVersion.add")}
                        </h4>
                        <section className="flex-col px-25p mb-50p">
                            <Input
                                label={t("mobileVersion.currentVersions")}
                                isEditing={true}
                                placeholder={t("mobileVersion.enterCurrentVersions")}
                                {...formMethods.register("currentVersions")}
                                onChange={handleInputChange}
                                value={formMethods.watch("currentVersions")}
                            />
                        </section>
                        <div className="w-full bg-gray-bg p-6 flex rounded-b-20p ">
                            <Button
                                text={modalData?.item._id ? t("save") : t("add")}
                                isDisabled={isLoading}
                            />
                            <Button
                                text={t("cancel_2")}
                                type="tertiary"
                                className="ml-10p"
                                htmlType="button"
                                onClick={closeModal}
                                isDisabled={isLoading}
                            />
                        </div>
                    </section>
                </form>
            </FormProvider>
        );
    }
);
