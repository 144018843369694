import { toJS } from "mobx";
import { langTabs } from "../../../ui/organisms/LanguageTabChanger";
import { SUPPORTED_LANGUAGES } from "../../utils/i18n";
import { ArticlePayload } from "../types";
import {
    Article,
    Block,
    Languages,
    Question,
    LangMap,
    LanguagesEnum
} from "./../../types";

const transformBlocks = (blocks: LangMap<Block[]>): LangMap<Block[]> => {
    let transformedBlocks: LangMap<Block[]> = {};
    for (const item in blocks) {
        if ({ ...blocks }[item]) {
            transformedBlocks[item as LanguagesEnum] = {
                ...blocks
            }[item];
        }
    }

    return transformedBlocks;
};

export const addCorrectAnswerField = (questions: Question[]) =>
    questions.map(question => ({
        ...question,
        correctAnswer: question.answers
            .findIndex(answer => answer.isCorrect === true)
            .toString()
    }));

export const transformArticles = (
    article: Article,
    language?: Languages
): Partial<ArticlePayload> => {
    return {
        ...article,
        manufacturer: {
            label: article.manufacturer?.name,
            value: article.manufacturer?._id
        },
        description: article.description ?? "",
        cover: article?.coverId || article?.cover,
        blocks: article?.blocks
            ? // ? Object.fromEntries(
              //       SUPPORTED_LANGUAGES.map(lang => [
              //           lang,
              //           article?.blocks?.[lang] || undefined
              //       ])
              //   )
              transformBlocks(toJS(article.blocks))
            : Object.fromEntries(SUPPORTED_LANGUAGES.map(lang => [lang, []])),

        quiz: article?.quiz
            ? {
                  ...article.quiz,
                  questions: article.quiz.questions
                      ? Object.fromEntries(
                            SUPPORTED_LANGUAGES.map(lang => [
                                lang,
                                article.quiz.questions?.[lang]
                                    ? addCorrectAnswerField(
                                          article.quiz.questions[lang]!
                                      )
                                    : []
                            ])
                        )
                      : undefined
              }
            : {
                  questions: Object.fromEntries(
                      SUPPORTED_LANGUAGES.map(lang => [lang, []])
                  )
              },
        currentLang: language
            ? langTabs.find(lang => lang.value === language)
            : langTabs.find(lang => lang.value === "en")!
    };
};

export const transformPayload = (
    values: Partial<ArticlePayload>
): Partial<Article> => {
    return {
        manufacturerId: values?.manufacturer?.value,
        coverId: values?.coverId,
        description: values?.description,
        blocks: values?.blocks,
        quiz: values?.quiz
    };
};
