import { TFunction } from "i18next";
import Joi from "joi";
import { MobileVersion } from "../../types";

export const getMobileVersionSchema = (t: TFunction) =>
    Joi.object<MobileVersion>({
        currentVersions: Joi.string()
            .required()
            .messages({
                "string.empty": t("fieldIsRequired"),
                "any.required": t("fieldIsRequired")
            })
    }).unknown(true);

