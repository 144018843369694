import {
    JamesIcon,
    RobertIcon,
    SpectatorIcon,
    GamberoRosso
} from "../../ui/atoms/Icon";
import { OptionType } from "../../ui/atoms/Select";

export const AGENT_RATINGS = [
    {
        code: "rosso",
        max: 100,
        min: 0,
        allowDecimal: true,
        title: "Gambero Rosso",
        Icon: GamberoRosso
    },
    {
        code: "james",
        max: 100,
        min: 0,
        title: "James Suckling",
        Icon: JamesIcon
    },
    {
        code: "spectator",
        max: 100,
        min: 0,
        title: "Wine Spectator",
        Icon: SpectatorIcon
    },
    {
        code: "robert",
        max: 100,
        min: 0,
        title: "Robert Parker",
        Icon: RobertIcon
    }
];

export const SWEETNESS_OPTIONS: OptionType[] = [
    { label: "sweetnessOptions.2", value: 2 },
    { label: "sweetnessOptions.4", value: 4 },
    { label: "sweetnessOptions.6", value: 6 },
    { label: "sweetnessOptions.8", value: 8 },
    { label: "sweetnessOptions.10", value: 10 }
];
export const ACIDITY_OPTIONS: OptionType[] = [
    { label: "acidityOptions.2", value: 2 },
    { label: "acidityOptions.4", value: 4 },
    { label: "acidityOptions.6", value: 6 },
    { label: "acidityOptions.8", value: 8 },
    { label: "acidityOptions.10", value: 10 }
];
export const TANNIN_OPTIONS: OptionType[] = [
    { label: "tanninOptions.2", value: 2 },
    { label: "tanninOptions.4", value: 4 },
    { label: "tanninOptions.6", value: 6 },
    { label: "tanninOptions.8", value: 8 },
    { label: "tanninOptions.10", value: 10 }
];

export const WINE_GROUP_OPTIONS: OptionType[] = [
    { label: "wineGroup.aromaticMineral", value: "aromaticMineral" },
    { label: "wineGroup.aromaticRich", value: "aromaticRich" },
    {
        label: "wineGroup.aromaticRichWithSugar",
        value: "aromaticRichWithSugar"
    },
    { label: "wineGroup.aromaticBalanced", value: "aromaticBalanced" },
    {
        label: "wineGroup.aromaticBalancedWithSugar",
        value: "aromaticBalancedWithSugar"
    },
    { label: "wineGroup.aromaticTannin", value: "aromaticTannin" },
    { label: "wineGroup.aromaticFine", value: "aromaticFine" },
    { label: "wineGroup.baseWithSugar", value: "baseWithSugar" },
    { label: "wineGroup.aged", value: "aged" },
    { label: "wineGroup.agedOld", value: "agedOld" },
    { label: "wineGroup.highAcid", value: "highAcid" },
    { label: "wineGroup.highTannin", value: "highTannin" },
    { label: "wineGroup.highTanninYoung", value: "highTanninYoung" },
    { label: "wineGroup.lightAromatic", value: "lightAromatic" },
    { label: "wineGroup.light", value: "light" },
    { label: "wineGroup.mineral", value: "mineral" },
    { label: "wineGroup.mineralWithSugar", value: "mineralWithSugar" },
    { label: "wineGroup.youngWithPotential", value: "youngWithPotential" },
    { label: "wineGroup.richBalanced", value: "richBalanced" },
    { label: "wineGroup.rich", value: "rich" },
    { label: "wineGroup.richHighAcid", value: "richHighAcid" },
    { label: "wineGroup.balanced", value: "balanced" },
    { label: "wineGroup.balancedWithSugar", value: "balancedWithSugar" },
    { label: "wineGroup.fine", value: "fine" }
];
