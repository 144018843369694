import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import {
    throwErrorToast,
    throwSuccessToast
} from "../../../../../ui/organisms/Toaster";
import { authStore } from "../../../../auth/store/AuthStore";
import { useModal } from "../../../../modalpage/hooks";
import { DeliveryCountryAPI } from "../../../../utils/api/requests/delivery-country-requests";

interface RemoveCountryOfDeliveryModalProps {
    id: string;
}

export const REMOVE_COUNTRY_OF_DELIVERY = "REMOVE_COUNTRY_OF_DELIVERY";

export const RemoveCountryOfDeliveryModal: FC<RemoveCountryOfDeliveryModalProps> = observer(
    ({ id }) => {
        const { t } = useTranslation();
        const { user } = useContext(authStore);
        const [isLoading, setLoading] = useState<boolean>(false);
        const {
            register,
            closeModal,
            activeModalId,
            modalData,
            modalCallback
        } = useModal<{
            id: string;
        }>();

        useEffect(() => {
            register({
                id
            });
        }, [id, register]);

        const onDelete = async () => {
            setLoading(true);
            try {
                const data = await DeliveryCountryAPI.deleteDeliveryCountry({
                    id: modalData?.id!,
                    role:
                        user?.role === "manufacturer" ? "manufacturer" : "admin"
                });
                if (data) {
                    closeModal();
                    throwSuccessToast(t("deliveryCountry.deleted"));
                    const cb = modalCallback[REMOVE_COUNTRY_OF_DELIVERY];
                    cb && cb(true);
                }
            } catch (error) {
                throwErrorToast(t("error"), t("unknownError"));
            } finally {
                setLoading(false);
            }
        };

        if (activeModalId !== id) return null;

        return (
            <section className="w-600p bg-white rounded-20p flex flex-col text-dark-main">
                <h2 className="text-18 font-semibold p-6">
                    {t("areUSureToDelete")}
                </h2>
                <span className="text-14 leading-5 px-6 mb-16">
                    {t("dataWillBeDeleted", {
                        word: t("deliveryCountry.plural_3")
                    })}
                </span>

                <div className="w-full bg-gray-bg p-6 flex rounded-b-20p">
                    <Button
                        text={t("cancel_2")}
                        onClick={closeModal}
                        className="mr-10p"
                        isDisabled={isLoading}
                    />
                    <Button
                        text={t("deleteConfirm")}
                        onClick={onDelete}
                        type="tertiary"
                    />
                </div>
            </section>
        );
    }
);
