import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import { OptionType } from "../../../../../ui/atoms/Select";
import { throwErrorToast, throwSuccessToast } from "../../../../../ui/organisms/Toaster";
import { FetchSelect } from "../../../../../ui/molecules/FetchSelect";
import { DirectoryAPI } from "../../../../utils/api/requests/directory-requests";
import { useMutation } from "../../../../utils/hooks/useMutation";
import { modalPageStore } from "../../../../modalpage/store/ModalPageStore";

interface addModalProps {
    id: string;
}

export const MANUFACTURER_RELATED_ADD_MODAL = "MANUFACTURER_RELATED_ADD_MODAL";

interface RelatedManufacturerPayload {
    relatedManufacturer: OptionType;
    manufacturer: OptionType;
}

export const RelatedManufacturerModal: FC<addModalProps> = observer(({ id }) => {
    const {
        register,
        closeModal,
        activeModalId,
        modalData,
        modalCallback,
    } = useContext(modalPageStore);

    const manufacturer = modalData?.manufacturer;
    const { t } = useTranslation();
    const form = useForm<RelatedManufacturerPayload>({
        mode: "onChange",
    });

    const { handleSubmit, formState, watch } = form;
    const manufacturerKey = watch("manufacturer")?.toString() + "manufacturer";
    useEffect(() => {
        register({
            id
        });
    }, [id, register]);

    const { isDirty } = formState;

    const { mutate, isLoading } = useMutation({
        fetchFn: async (values: RelatedManufacturerPayload) => {

            const formData = {
                manufacturerId: manufacturer,
                relatedManufacturerId: values.manufacturer.value,
            };
            return DirectoryAPI.addRelatedManufacturer(formData);
        },
        onSuccess: data => {
            closeModal();
            const cb = modalCallback[id];
            cb && cb(true, data);
            if (modalData?.item?._id) {
                throwSuccessToast(
                    t(`changesSaved`),
                    t(`manufacturer.changesSaved`)
                );
            } else {
                throwSuccessToast(
                    t(`manufacturer.successTitle`),
                    t(`manufacturer.successDescription`)
                );
            }
        },
        onError: error => {
            if (
                error.response?.data.error.data?.issues?.length &&
                error.response?.data.error.data?.issues[0]?.rule === "uniqLangMap"
            ) {
                throwErrorToast(
                    t("error"),
                    t(`manufacturer.manufacturerNotUnique`)
                );
            } else {
                throwErrorToast(t("error"), t("unknownError"));
            }
        }
    });

    const onSubmit = async (values: RelatedManufacturerPayload) => {
        await mutate(values);
    };

    if (activeModalId !== id) return null;

    return (
        <FormProvider {...form}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="max-w-full h-full bg-white rounded-20p flex flex-col text-dark-main overflow-y-auto"
                style={{ maxHeight: "90vh" }}
            >
                <h4 className="text-18 font-semibold p-6">
                    {modalData?.item?._id
                        ? `${t("editing")} ${t("manufacturer.plural_2").toLowerCase()}`
                        : t(`manufacturer.addTitle`)}
                </h4>
                <div key={manufacturerKey} className="mb-80 flex-grow">
                    <FetchSelect
                        fetchFn={DirectoryAPI.getManufacturers}
                        placeholder={t("manufacturer.plural_0")}
                        name="manufacturer"
                        isSearchable={true}
                        label={t("manufacturer.plural_0")}
                        className="px-6"
                    />
                </div>
                <div className="w-full bg-gray-bg p-6 flex rounded-b-20p mt-auto">
                    <Button
                        text={modalData?.item?._id ? t("save") : t("create")}
                        isDisabled={!isDirty || isLoading}
                    />
                    <Button
                        text={t("cancel_2")}
                        type="tertiary"
                        className="ml-4"
                        htmlType="button"
                        onClick={closeModal}
                    />
                </div>
            </form>
        </FormProvider>
    );
});
