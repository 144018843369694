import {
    UpdateMobileVersionModal,
    UPDATE_MOBILE_VERSION
} from "./UpdateMobileVersion";
import {
    RemoveMobileVersionModal,
    REMOVE_MOBILE_VERSION
} from "./RemoveMobileVersion";

export const  MobileVersionModals = () => {
    return (
        <>
            <UpdateMobileVersionModal id={UPDATE_MOBILE_VERSION} />
            <RemoveMobileVersionModal id={REMOVE_MOBILE_VERSION} />
        </>
    );
};
