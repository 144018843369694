import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { OptionType, Select } from "../../../../ui/atoms/Select";
import { articleListForSelect } from "../../../fields/promo";
import { getSafariFriendlyDate } from "../../../catalogue/utils/getSafariFriendlyDate";
import { DirectoryAPI } from "../../../utils/api/requests/directory-requests";
import cn from "classnames";

interface ArticleSelectProps {
    isEditing?: boolean;
    label?: string;
    id?: string;
    disabled?: boolean;
    className?: string;
}
export const ArticleSelect: FC<ArticleSelectProps> = ({
    isEditing,
    label,
    id,
    disabled,
    className
}) => {
    const { t } = useTranslation();
    const classes = cn("mb-50p", className);
    const fetch = useCallback(
        async (value: string) => {
            const manufacturerId = id;
            const data = await DirectoryAPI.getManufacturerArticles({
                q: value,
                manufacturerId: manufacturerId,
                _fields: articleListForSelect
            });
            return data.items.map(
                article =>
                    ({
                        label: `${
                            article?.manufacturer?.name
                        } (${getSafariFriendlyDate(article?.createDate).format(
                            "DD.MM.YYYY"
                        )})`,
                        value: article._id
                    } as OptionType)
            );
        },
        [id]
    );
    return (
        <Select
            defaultOptions={true}
            isEditing={isEditing}
            loadOptions={fetch}
            isSearchable={false}
            label={label}
            isDisabled={!disabled}
            placeholder={t("notChosen")}
            name="articles"
            className={classes}
        />
    );
};
