import { Country, PlatformSelection } from "../../types";
import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { PromosAPI } from "../../utils/api/requests/promos-requests";
import { transformPayload } from "../utils/transformPlatformSelection";
import { PlatformSelectionPayload } from "../types";

export class PlatformSelectionStore {
    constructor() {
        makeAutoObservable(this);
    }

    platformSelection: PlatformSelection | null = null;
    country: Partial<Country> | null = null
    isEditing: boolean = false;
    isLoading: boolean = false;

    clear = () => {
        this.platformSelection = null;
        this.isEditing = false;
        this.isLoading = false;
        this.country = null
    };

    loadPlatformSelection = async (id: string) => {
        this.isLoading = true;
        try {
            const data = await PromosAPI.getPlatformSelection({ id });
            this.platformSelection = data;
        } finally {
            this.isLoading = false;
        }
    };

    setEditing = (flag: boolean) => {
        this.isEditing = flag;
    };
    setLoading = (flag: boolean) => {
        this.isLoading = flag;
    };
    setCountry = (country: Partial<Country> ) => {
        this.country = country;
    };
    updateOrCreatePlatformSelection = async (
        values: Partial<PlatformSelectionPayload>
    ) => {
        const formData = transformPayload(values);
        let data;
        if (this.platformSelection && this.platformSelection._id) {
            data = await PromosAPI.updatePlatformSelection({
                id: this.platformSelection._id,
                formData
            });
            this.platformSelection = data;
        } else {
            data = await PromosAPI.createPlatformSelection({
                formData : {
                    countryId: this.country?._id,
                    productIds: formData.productIds
                }
            });
        }
        return data;
    };
}

export const platformSelectionStore = createContext(
    new PlatformSelectionStore()
);
